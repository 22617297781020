import { createSlice } from '@reduxjs/toolkit'

const toastsSlice = createSlice({
    name: 'toasts',
    initialState: {
        toast: {
            error: null,
            success: null,
        },
    },
    reducers: {
        clear(state) {
            state.toast = {}
        },
        addError(state, action) {
            state.toast = {
                error: action.payload.message,
                success: null,
            }
        },
        addSuccess(state, action) {
            state.toast = {
                success: action.payload.message,
                error: null,
            }
        },
    },
})

export default toastsSlice
