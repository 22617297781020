import React, { useState, useEffect, useCallback } from 'react'

let logoutTimer

const AuthContext = React.createContext({
    user: {},
    token: '',
    isLoggedIn: false,
    isAdmin: false,
    setIsAdmin: () => {},
    login: () => {},
    logout: () => {},
})

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime()
    const adjExpirationTime = new Date(expirationTime).getTime()

    return adjExpirationTime - currentTime
}

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem('token')
    const storedExpirationDate = localStorage.getItem('expirationTime')
    const storedUser = localStorage.getItem('user')

    const remainingTime = calculateRemainingTime(storedExpirationDate)

    if (remainingTime <= 3600) {
        localStorage.removeItem('token')
        localStorage.removeItem('expirationTime')
        localStorage.removeItem('user')
        return null
    }

    return {
        token: storedToken,
        duration: remainingTime,
        user: JSON.parse(storedUser),
    }
}

export const AuthContextProvider = (props) => {
    const tokenData = retrieveStoredToken()

    let initialToken
    if (tokenData) {
        initialToken = tokenData.token
    }

    const [user, setUser] = useState(tokenData ? tokenData.user : {})
    const [token, setToken] = useState(initialToken)
    const [isAdmin, setIsAdmin] = useState(false)

    const userIsLoggedIn = !!token

    const logoutHandler = useCallback(() => {
        setToken(null)
        setIsAdmin(false)
        localStorage.removeItem('token')
        localStorage.removeItem('expirationTime')
        localStorage.removeItem('user')

        if (logoutTimer) {
            clearTimeout(logoutTimer)
        }
    }, [])

    const loginHandler = (token, expirationTime, user) => {
        setToken(token)
        setUser(user)
        localStorage.setItem('token', token)
        localStorage.setItem('expirationTime', expirationTime)
        localStorage.setItem('user', JSON.stringify(user))

        const remainingTime = calculateRemainingTime(expirationTime)
        logoutTimer = setTimeout(logoutHandler, remainingTime)
    }

    const setIsAdminHandler = (value) => {
        setIsAdmin(value)
    }

    const expirationTime = localStorage.getItem('expirationTime')
    if (expirationTime) {
        if (logoutTimer) {
            clearTimeout(logoutTimer)
        }
        const remainingTime = calculateRemainingTime(expirationTime)
        logoutTimer = setTimeout(logoutHandler, remainingTime)
    }

    useEffect(() => {
        if (tokenData) {
            logoutTimer = setTimeout(logoutHandler, tokenData.duration)
        }
    }, [tokenData, logoutHandler])

    const contextValue = {
        user: user,
        token: token,
        isLoggedIn: userIsLoggedIn,
        isAdmin: isAdmin,
        setIsAdmin: setIsAdminHandler,
        login: loginHandler,
        logout: logoutHandler,
    }

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext
