import { CSpinner } from '@coreui/react'
import React from 'react'

const Loader = () => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <CSpinner />
        </div>
    )
}

export default Loader
