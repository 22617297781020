import { configureStore } from '@reduxjs/toolkit'

import uiSlice from './ui-slice'
import toastsSlice from './toast-slice'

const store = configureStore({
    reducer: { ui: uiSlice.reducer, toasts: toastsSlice.reducer },
})

export const toastsActions = toastsSlice.actions
export const uiActions = uiSlice.actions

export default store
