import React from 'react'
import { CToast, CToastBody, CToastClose } from '@coreui/react'

const Success = (props) => {
    return (
        <CToast
            autohide={true}
            delay={5000}
            visible={true}
            color="success"
            className="text-white align-items-center"
        >
            <div className="d-flex">
                <CToastBody>{props.message ?? 'Success'}</CToastBody>
                <CToastClose className="me-2 m-auto" white />
            </div>
        </CToast>
    )
}

export default Success
