import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
    fallbackLng: 'bg',
    lng: 'bg',
    resources: {
        bg: {
            translations: require('./locales/bg/translations.json'),
        },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    saveMissing: true,
})

i18n.languages = ['bg']

export default i18n
