import React from 'react'
import { CToast, CToastBody, CToastClose } from '@coreui/react'

const Error = (props) => {
    return (
        <CToast
            autohide={true}
            delay={5000}
            visible={true}
            color="danger"
            className="text-white align-items-center"
        >
            <div className="d-flex">
                <CToastBody>{props.message}</CToastBody>
                <CToastClose className="me-2 m-auto" white />
            </div>
        </CToast>
    )
}

export default Error
