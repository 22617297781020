import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
    name: 'ui',
    initialState: { sidebarShow: true, sidebarUnfoldable: null },
    reducers: {
        toggleSidebar(state) {
            state.sidebarShow = !state.sidebarShow
        },
        changeSidebarStatus(state, action) {
            state.sidebarShow = action.payload.status
        },
        toggleUnfoldable(state) {
            state.sidebarUnfoldable = !state.sidebarUnfoldable
        },
    },
})

export default uiSlice
