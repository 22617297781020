import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import Loader from './components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { CToaster } from '@coreui/react'
import Error from './components/toasts/Error'
import Success from './components/toasts/Success'
import { toastsActions } from './store/store'
import { useAuthContext } from './hooks/use-auth-context'
import OneSignal from 'react-onesignal'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./pages/login/Login'))
const ForgotPassword = React.lazy(() => import('./pages/forgot-password/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./pages/forgot-password/ResetPassword'))
const Page404 = React.lazy(() => import('./pages/page404/Page404'))
const Page500 = React.lazy(() => import('./pages/page500/Page500'))

function App() {
    const dispatch = useDispatch()
    const authCtx = useAuthContext()
    const toast = useSelector((state) => state.toasts.toast)

    let toastElement = null
    if (toast.error) {
        toastElement = <Error message={toast.error} />
        dispatch(toastsActions.clear())
    }
    if (toast.success) {
        toastElement = <Success message={toast.success} />
        dispatch(toastsActions.clear())
    }

    useEffect(() => {
        if (!authCtx.isLoggedIn) {
            return
        }
        OneSignal.init({
            appId: '673e392c-e1bf-4298-b407-9c81a19e62bf',
            safari_web_id: 'web.onesignal.auto.2900aeea-27da-4bc5-9f95-2e3d9a76781c',
            allowLocalhostAsSecureOrigin: true,
        }).then(() => {
            OneSignal.Slidedown.promptPush()

            OneSignal.Notifications.addEventListener('permissionChange', (status) => {
                if (status) {
                    OneSignal.User.addTag('user', authCtx.user.name + '')
                    OneSignal.User.addTag('user_id', authCtx.user.id + '')
                }
            })
        })
    }, [authCtx.isLoggedIn])

    return (
        <BrowserRouter>
            <CToaster push={toastElement} placement="top-end" />
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route exact path="/404" name="Page 404" element={<Page404 />} />
                    <Route exact path="/500" name="Page 500" element={<Page500 />} />

                    {!authCtx.isLoggedIn && (
                        <>
                            <Route exact path="/login" name="Login Page" element={<Login />} />
                            <Route
                                exact
                                path="/forgot-password"
                                name="Forgot Password"
                                element={<ForgotPassword />}
                            />
                            <Route
                                exact
                                path="/reset-password/:token"
                                name="Reset Password"
                                element={<ResetPassword />}
                            />
                        </>
                    )}

                    <Route
                        path="*"
                        element={
                            authCtx.isLoggedIn ? (
                                <DefaultLayout />
                            ) : (
                                <Navigate to={'/login#' + window.location.pathname} />
                            )
                        }
                    />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default App
